<template>
  <v-card class="elevation-0">
    <v-form v-model="valid" ref="form">
      <!-- <v-row>
        <v-col cols="12"> -->
      <v-subheader class="px-0">
        Анкета автора необходима для получения коммерческого статуса.<br />
        Вводя данные вы тем самым подтверждаете их верность и несете полную
        ответственность за них.</v-subheader
      >

      <div v-if="currentUser && currentUser.can_sell_book">
        <v-text-field v-model="email" label="E-mail" disabled></v-text-field>
        <v-text-field
          :disabled="oldPseudonym ? true : false"
          v-model="pseudonym"
          name="inputpseudonym"
          label="Имя Фамилия(псевдоним)"
          hint="Отнеситесь серьезно, невозможно будет изменить в дальнейшем."
          :rules="requiredRules"
        ></v-text-field>
        <v-text-field
          :disabled="oldPhone ? true : false"
          v-model="phone"
          name="inputreal_phone"
          type="tel"
          label="Телефон"
          placeholder="+7(555) 555-5555"
          :rules="phoneRules"
        ></v-text-field>
        <v-text-field
          :disabled="oldRealName ? true : false"
          v-model="real_name"
          name="inputreal_name"
          label="Имя (реальное)"
          hint="Необходимо для выплат. Отнеситесь серьезно, невозможно будет изменить в дальнейшем."
          :rules="requiredRules"
        ></v-text-field>

        <v-text-field
          :disabled="oldRealSurname ? true : false"
          v-model="real_surname"
          name="inputreal_surname"
          label="Фамилия (реальная)"
          hint="Необходимо для выплат. Отнеситесь серьезно, невозможно будет изменить в дальнейшем."
          :rules="requiredRules"
        ></v-text-field>
        <v-text-field
          :disabled="oldAddress ? true : false"
          v-model="address"
          name="address"
          label="Адрес прописки"
          hint="Отнеситесь серьезно, невозможно будет изменить в дальнейшем."
          :rules="requiredRules"
        ></v-text-field>
        <v-text-field
          :disabled="oldNumber ? true : false"
          v-model="number"
          name="number"
          label="Номер паспорта"
          hint="Отнеситесь серьезно, невозможно будет изменить в дальнейшем."
          :rules="requiredRules"
        ></v-text-field>
        <v-text-field
          :disabled="oldSeries ? true : false"
          v-model="series"
          name="series"
          label="Серия паспорта"
          hint="Отнеситесь серьезно, невозможно будет изменить в дальнейшем."
          :rules="requiredRules"
        ></v-text-field>

        <v-menu
          ref="birthday_menu"
          v-model="birthday_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <!-- :return-value.sync="dates" -->
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="birthday_date"
              :rules="requiredRules"
              :disabled="oldBirthday ? true : false"
              label="Дата рождения в паспорте"
              prepend-icon="$vuetify.icons.calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="ru-ru"
            ref="picker"
            :first-day-of-week="1"
            v-model="birthday_date"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="birthday_menu = false"
              >Закрыть</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.birthday_menu.save(birthday_date)"
              >Принять</v-btn
            >
          </v-date-picker>
        </v-menu>

        <v-file-input
          v-model="scan1"
          v-if="!disabledScan1"
          clearable
          :disabled="disabledScan1"
          accept="image/png, image/jpeg, image/bmp"
          :rules="fileRules"
          label="Первый разворот паспорта"
        ></v-file-input>
        <v-file-input
          v-if="!disabledScan2"
          v-model="scan2"
          clearable
          :disabled="disabledScan2"
          accept="image/png, image/jpeg, image/bmp"
          :rules="fileRules"
          label="Разворот прописки"
        ></v-file-input>
        <v-file-input
          v-if="!disabledScan3"
          v-model="scan3"
          clearable
          :disabled="disabledScan3"
          accept="image/png, image/jpeg, image/bmp"
          :rules="fileRules"
          label="Ваше фото с паспортом в руках"
        ></v-file-input>

        <!-- <v-text-field
            v-model="ymoney"
            name="inputYmoney"
            label="Yandex Кошелек"
            required
            :rules="ymoneyRules"
          ></v-text-field> -->
        <v-subheader class="px-0"
          >Вы можете собирать средства на свою книгу. Укажите ссылку на вашу
          страницу сбора средств и она появится на странице книги.</v-subheader
        >
        <v-text-field
          v-model="donat"
          name="inputDonat"
          label="Ссылка на донат"
          :rules="donatRules"
        ></v-text-field>

        <div>
          Нажимая на кнопку Отправить вы тем самым даете свое согласие сайту на
          хранение и обработку ваших персональных данных
        </div>

        <v-btn
          class="mt-2"
          dark
          color="primary"
          :loading="settingsLoading"
          @click="submit"
          >Отправить</v-btn
        >
      </div>
      <div class="pa-8" v-else>
        <v-card-title class="headline justify-center"
          >Анкета доступна к заполнению, при выполнении следующих условий</v-card-title>
          <b>
        - есть на сайте уже одна полностью завершенная книга от 300 тыс
        символов<br />
        - есть вторая книга объемом не менее 50 тыс символов.</b>
      </div>
      <!-- </v-col>
      </v-row> -->

      <!-- <v-row>
                <v-col>
                  <v-subheader class="px-0"
                    >Задать номер телефона.</v-subheader
                  >
                  <v-btn
                    v-if="currentUser"
                    color="primary"
                    dark
                    :to="{
                      name: 'CabinetSettingsPassword',
                    }"
                    >Подтвердить номер телефона</v-btn
                  >
                </v-col>
              </v-row> -->
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  data: () => ({
    name: "",
    pseudonym: "",
    oldPseudonym: "",
    oldRealName: "",
    oldRealSurname: "",
    real_name: "",
    real_surname: "",
    address: "", //Место прописки
    oldAddress: "", //Место прописки
    number: "", //Номер паспорта
    oldNumber: "", //Номер паспорта
    series: "", //СЕрия паспорта
    oldSeries: "", //СЕрия паспорта
    email: "",

    // ymoney: "",
    donat: "",
    author: 0,
    valid: true,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    // nameRules: [
    //   v => !!v || "Поле обязательно",
    //   v => v.length >= 3 || "Минимум 3 символа"
    // ],
    numberRules: [
      (v) => !!v || "Поле обязательно",
      // v => Number.isInteger(Number(v)) || "Только числа",
      (v) => !v || /^[\d]*$/.test(v) || "Только цифры",
    ],
    aboutRules: [
      // v => !!v || 'Поле обязательно',
      (v) =>
        (!!v === v.length >= 10) === v.length <= 400 ||
        "Минимум 10 символов, максимум 400 символов",
    ],
    fileRules: [
      (v) => !!v || "Поле обязательно",
      (v) => !v || v.size < 2000000 || "Рзмер файла не должен превышать 2 MB!",
    ],
    // ymoneyRules: [],
    donatRules: [],

    settingsLoading: false,
    birthday_menu: false,
    birthday_date: null,
    oldBirthday: null,
    phone: "",
    oldPhone: "",

    scan1: null,
    scan2: null,
    scan3: null,
    disabledScan1: false,
    disabledScan2: false,
    disabledScan3: false,
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    cleanPhone(phone) {
      return phone.replace(/[^+\d]/g, "");
    },
    updateData() {
      if (this.currentUser) {
        this.name = this.currentUser.name;
        this.pseudonym = this.currentUser.pseudonym
          ? this.currentUser.pseudonym
          : "";
        this.oldPseudonym = this.pseudonym;

        // this.ymoney =
        //   this.currentUser.ymoney && this.currentUser.ymoney != "null"
        //     ? this.currentUser.ymoney
        //     : "";
        this.donat =
          this.currentUser.donat && this.currentUser.donat != "null"
            ? this.currentUser.donat
            : "";

        this.email = this.currentUser.email;
        this.author = +this.currentUser.author;
      }
      if (this.pass) {
        this.real_name = this.pass.name ? this.pass.name : "";
        this.oldRealName = this.pass.name;
        this.real_surname = this.pass.surname ? this.pass.surname : "";
        this.oldRealSurname = this.pass.surname;

        this.address = this.pass.address ? this.pass.address : "";
        this.oldAddress = this.pass.address;

        this.number = this.pass.number ? this.pass.number : "";
        this.oldNumber = this.pass.number;

        this.series = this.pass.series ? this.pass.series : "";
        this.oldSeries = this.pass.series;

        this.phone =
          this.pass.phone && this.pass.phone != "null" ? this.pass.phone : "";
        this.oldPhone = this.phone;

        this.birthday_date =
          this.pass.birthday_date && this.pass.birthday_date != "null"
            ? this.pass.birthday_date
            : null;
        this.oldBirthday = this.birthday_date;

        this.disabledScan1 =
          this.pass.path_scan1 && this.pass.path_scan1 != "null" ? true : false;
        this.disabledScan2 =
          this.pass.path_scan2 && this.pass.path_scan2 != "null" ? true : false;
        this.disabledScan3 =
          this.pass.path_scan3 && this.pass.path_scan3 != "null" ? true : false;

        // : new Date().toISOString().substr(0, 10);
      }
    },

    submit() {
      console.log(this.validate());
      if (!this.validate()) return;
      console.log(2222);
      let app = this;
      app.settingsLoading = true;
      let data = {
        // ymoney: app.ymoney,
        donat: app.donat,
      };

      if (!app.oldBirthday) {
        data.birthday_date = app.birthday_date;
      }

      if (!app.oldPseudonym) {
        data.pseudonym = app.pseudonym;
      }

      if (!app.oldRealName) {
        data.real_name = app.real_name;
      }

      if (!app.oldRealSurname) {
        data.real_surname = app.real_surname;
      }

      if (!app.oldPhone) {
        data.phone = app.cleanPhone(app.phone);
      }
      if (!app.oldAddress) {
        data.address = app.address;
      }

      if (!app.oldNumber) {
        data.number = app.number;
      }

      if (!app.oldSeries) {
        data.series = app.series;
      }

      if (!app.disabledScan1) {
        data.scan1 = app.scan1;
      }
      if (!app.disabledScan2) {
        data.scan2 = app.scan2;
      }
      if (!app.disabledScan3) {
        data.scan3 = app.scan3;
      }

      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }

      formData.append("_method", "PUT"); //костыль для laravel

      this.$store
        .dispatch("account/updateAccountAuthor", formData)
        .then(() => {
          app.settingsLoading = false;
          this.$emit("update-form");
        })
        .catch(() => {
          app.settingsLoading = false;
        });
    },
    getData() {
      let app = this;
      this.$store.dispatch("account/getEditAuthor").then(() => {
        app.updateData();
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      pass: "account/pass",
    }),
    pseudonymRules() {
      const rules = [];
      if (this.author) {
        const rule = (v) => !!v || "Поле обязательно";
        const rule2 = (v) => (v && v.length >= 3) || "Минимум 3 символа";
        rules.push(rule);
        rules.push(rule2);
      }

      return rules;
    },
    phoneRules() {
      const rules = [];
      if (this.author) {
        const rule = (v) => !!v || "Поле обязательно";
        const rule2 = (v) => (v && v.length >= 11) || "Минимум 11 символов";
        rules.push(rule);
        rules.push(rule2);
      }

      return rules;
    },
  },
  watch: {
    currentUser() {
      if (this.currentUser) {
        this.updateData();
      }
    },
    birthday_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
